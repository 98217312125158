import "./style.css";
import React, { useState, useCallback, useEffect } from 'react';
import Room from './Room';
import { useHistory } from 'react-router';
import { SideBar } from '../sideBar';
import { ChatWindow } from './chatWindow';
import { DoctorNotes } from '../userDataComponent/doctorNotes/doctorNotes';
import { Link } from "react-router-dom";
import { Management } from './Management'
import jwt from 'jwt-simple';
import Moment from 'moment';
import axios from 'axios';
import { server } from "../../config/server";

const VideoChat = ({ roomName, token, chatInfo }) => {
  // const [username, setUsername] = useState('');
  const history = useHistory();
  const [setVideoCallStatus] = useState(0);
  const [patient, setPatient] = React.useState('');
  const [reviewcount, setReviewCount] = React.useState(0);
  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [timecount, setChatTime] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(0);
  const [timerHandle, setTimerHandle] = useState(-1);
  // const [channelName, setChannelName] = useState("");
  // eslint-disable-next-line
  // skill code to avoid memory leak error
  let isMountRef1 = false;
  let isMountRef2 = false;
  const createNewRoom = () => {
    setVideoCallStatus(1);
  }
  // eslint-disable-next-line
  const config = {
    headers: {
      'Authorization': 'Bearer ' + localStorage.token
    }
  }

    useEffect(()=>{
      isMountRef1 = true;
      let data = jwt.decode(chatInfo, "xxx");
      let patient = data.userInfo;
      if(isMountRef1)
        setPatient(patient);
      return () => { isMountRef1 = false };
    }, [chatInfo]);

    useEffect(()=>{
      isMountRef2 = true;
      let handle = setInterval(updateTimer, 1000);
      console.log("hihihihi");
      setTimerHandle(handle);
      return () => { isMountRef2 = false };
    },[]);
    
    // useEffect(() => {
    //   let intervalId;
  
    //   if (isActive) {
    //     intervalId = setInterval(() => {
    //       const secondCounter = counter % 60;
    //       const minuteCounter = Math.floor(counter / 60);
  
    //       const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}`: secondCounter;
    //       const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}`: minuteCounter;
  
    //       setSecond(computedSecond);
    //       setMinute(computedMinute);
  
    //       setCounter(counter => counter + 1);
    //     }, 1000)
    //   }
  
    //   return () => clearInterval(intervalId);
    // }, [isActive, counter])
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get('info');
    let time = 0;
    const updateTimer = () => {
      time = time + 1;
      let second = time % 60 < 10 ? '0'+`${time%60}` : `${time%60}`;
      let minute = Number.parseInt(time / 60) % 60 < 10 ? '0'+`${Number.parseInt(time/60)%60}` : `${Number.parseInt(time/60)%60}`;
      if(isMountRef1)
      {
        setSecond(second);
        setMinute(minute);
      }
    }

    const endingCallRequest = () => {
      console.log("endingcall", localStorage);
      const params = {
        appointment_id: patient.appointmentId,
        status: false,
        appointment_status: 6,
        timestamp: Date.now()
      }
      console.log(params);
      axios
      .post(server.serverURL + "v1/manage-telehealth-call", params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then(function (response) {
        console.log("successfuly ended!!!");
      });
    }
  
    const holdingCallRequest = () => {
      console.log("holdingcall", localStorage);
      const params = {
        appointment_id: patient.appointmentId,
        status: false,
        appointment_status: 5,
        timestamp: Date.now()
      }
      console.log(params);
      axios
      .post(server.serverURL + "v1/manage-telehealth-call", params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then(function (response) {
        console.log("successfuly holded!!!");
      });
    }

    const handleLogout = (hangup) => {
      console.log("reviewcount");
      console.log(reviewcount);
      if(reviewcount>=6)
      {
        endingCallRequest();
      }
      else{
        holdingCallRequest();
      }
      clearInterval(timerHandle);
      if(foo === "videocall"){
        // history.push("/calendar");
        // window.location.reload();
        window.location.href = "/calendar";
      }
      else {
        // history.push("/chat");
        // window.location.reload();
        window.location.href = "/chat";
      }
    };
    const updateReviewCount = (n) => {
      setReviewCount(n);
    }
    let dates = Date()
  let render;
  render = (
    <div style={{ width: "100%", height: "100%" }}>
      <SideBar select={"chat"} />
      <div className="main-section">
        <div style={{ width: "100%", height: "100%" }}>
          <div className="row video-window d-flex" style={{ width: "96%", height: "68vh", minHeight: "66vh", marginBottom: "30px" }}>
            <div className="float-videochat mt-0" style={{height:"70%", width:"40%", left: "5%", zIndex:20}}>
              <div className="tele-icon d-flex justify-content-between">
                  <img src="/assets/images/tele-icon.png" width={230}/>                
                  <div className="btn btn-indigo" style={{height:39, width:81, display:"none"}} onClick={handleLogout}>Back</div>
              </div>            
              <div className="d-flex justify-content-between fw-600">
                  <div>
                      <div className="pl-2 pt-1" style={{color:"#303234"}}>Call Duration:</div>
                      <div className="pl-2 pt-1" style={{color:"#303234"}}>{minute} : {second}</div>
                  </div>
                  <div>
                      <div className="pr-2 pt-0" style={{color:"#303234"}}>{patient.patient_name}</div>
                      <div className="pr-2 pt-1" style={{color:"#303234"}}>{patient.DOB !== undefined && patient.DOB !== null && patient.DOB !== "" ? Moment(patient.DOB).format('MM/DD/YYYY'): Moment().format('MM/DD/YYYY')}</div>
                  </div>
                </div>
              <div className="card-section" style={{
                width: "100%",
                height: "90%",
                padding: 0
              }}>                
                <Room roomName={roomName} chatInfo={chatInfo} token={token} handleLogout={handleLogout} backgroundImage={patient.userimage}/>
              </div>
            </div>
            <div className="col-6" style={{zIndex:"10!important"}}></div>            
            <div className="col-6 bg-white manageradius mt-0 shadow">
              <Management page={"video"} roomName={roomName} chatInfo={chatInfo} updateReview={updateReviewCount} />
            </div>         
          </div>
        </div>
      </div>
    </div>
  );
  return render;
};

export default VideoChat;
