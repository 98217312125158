import React from 'react';
import '../style.css';

// const diagnosesData = [
//     {
//         title: "Type1 Diabetes",
//         description: "10-03-2019",
//     }, {
//         title: "Hyperetension",
//         description: "10-03-2019",
//     }, {
//         title: "Hyperlipidemia",
//         description: "10-03-2019",
//     }
// ]
export const Diagnoses = () => {
    return <div className="medical-no-report">
        <p className="medical-no-report-text">No Reported Diagnoses</p>
    </div>
    
}
