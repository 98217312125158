import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import { Icon } from '@iconify/react';
import phoneHangUp from '@iconify/icons-icomoon-free/phone-hang-up';
import volumeMute2 from '@iconify/icons-icomoon-free/volume-mute2';
import { Link } from "react-router-dom";
import axios from 'axios';
import { server } from "../../config/server";
import jwt from "jwt-simple";

const Room = ({ roomName, chatInfo, token, handleLogout, backgroundImage }) => {

  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [switchs, setswitchs] = useState(false);
  const [patient, setPatient] = React.useState({});
  useEffect(() => {
    if (token) {
      const participantConnected = participant => {
        setParticipants(prevParticipants => [...prevParticipants, participant]);
      };

      const participantDisconnected = participant => {
        setParticipants(prevParticipants =>
          prevParticipants.filter(p => p !== participant)
        );
      };

      let data = jwt.decode(chatInfo, "xxx");
      let pat = data.userInfo;
      console.log("startingcall2");
      console.log(pat.appointmentId);
      console.log(pat);
      setPatient(pat);
      //startingCallRequest();
      //pushNotification();
      Video.connect(token, {
        name: roomName
      }).then(room => {
        setRoom(room);
        console.log("startingcall1");
        //here send starting call api request
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);
      });

      return () => {
        setRoom(currentRoom => {
          if (currentRoom && currentRoom.localParticipant.state === 'connected') {
            currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            endingCallRequest();
            return null;
          } else {
            return currentRoom;
          }
        });
      };
    }
  }, [roomName, token]);

  const disconnectRoom = () => {
    if(room != null)
    {
      room.disconnect();
      console.log("disconnecting");
      //here send ending call api request
    }
  }

  const startingCallRequest = () => {
    console.log("startingcall", localStorage);
    const params = {
      appointment_id: patient.appointmentId,
      status: true,
      appointment_status: 4,
      timestamp: Date.now()
    }
    console.log(params);
    axios
    .post(server.serverURL + "v1/manage-telehealth-call", params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
    .then(function (response) {
      console.log("successfuly started!!!");
    });
  }

  const endingCallRequest = () => {
    console.log("endingcall", localStorage);
    const params = {
      appointment_id: patient.appointmentId,
      status: false,
      appointment_status: 6,
      timestamp: Date.now()
    }
    console.log(params);
    axios
    .post(server.serverURL + "v1/manage-telehealth-call", params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
    .then(function (response) {
      console.log("successfuly ended!!!");
    });
  }

  const holdingCallRequest = () => {
    console.log("holdingcall", localStorage);
    const params = {
      appointment_id: patient.appointmentId,
      status: false,
      appointment_status: 5,
      timestamp: Date.now()
    }
    console.log(params);
    axios
    .post(server.serverURL + "v1/manage-telehealth-call", params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    })
    .then(function (response) {
      console.log("successfuly holded!!!");
    });
  }

  const pushNotification = () => {
    const params = {
      appointment_id: patient.appointmentId,
      patient_id: patient.id,
      notification_id: "2",
      room_name: roomName,
      title: "Time to Call " + localStorage.userName,
      body: "Your Telemedicine call with " + localStorage.userName +" starts soon. Click here to began the Call",
    }
    console.log("push notification");
    console.log(params);
    console.log(patient);
    console.log(localStorage);
    axios
      .post(server.serverURL + "v1/push-notify", params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then(function (response) {
        console.log("successfuly send notification!!!");
      });
    }
  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} isRecipient={true} />
  ));

  const onHandleLogout = () => {
    disconnectRoom();
    handleLogout();
  }
  /* console.log(room, "room")
  console.log(participants, "particpants") */
  return (
    <div className="room" style={{
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: "40px",
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover"
    }}>
      {remoteParticipants}
      <div className="remote-participants" style={{
        position: "absolute",
        width: "150px",
        height: "150px",
        bottom: "10%",
        right: "10%",
        overflow: "hidden",
        borderRadius: "50%"
      }}>
        
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            isRecipient={switchs}
          />
        ) : null}
      </div>
      <div style={{ position: "absolute", left: "5%", bottom: "2%", cursor: "pointer", zIndex: "100!important" }} className="video-hang-button">
        <Icon color="white" fontSize="30px" icon={phoneHangUp} onClick={()=>{onHandleLogout()}} />
      </div>
      {/* <div style={{ position: "absolute", left: "20%", bottom: "3%", }} className="video-mute-button">
        <Icon color="white" fontSize="30px" icon={volumeMute2} />
      </div> */}
      {/* <div style={{ position: "absolute", right: "5%", bottom: "-2%"}}>
        <img className="img-circle" src={localStorage.userImage} style={{width:"150px", height:"150px"}}/>
      </div>      */}
    </div>
  );
};

export default Room;


