export const GET_USER = 'GET_USER';
export const USER_SELECT = 'USER_SELECT';
export const GET_PATIENTS_LIST = 'GET_PATIENTS_LIST';
export const SET_PATIENT_SELECTED = 'SET_PATIENT_SELECTED';
export const GET_PATIENT_PERSONAL_DATA = 'GET_PATIENT_PERSONAL_DATA';
export const GET_CHAT_PATIENT_TARGET = 'GET_CHAT_PATIENT_TARGET';
export const SET_GET_CHAT_PATIENT_TARGET = 'SET_GET_CHAT_PATIENT_TARGET';
export const GET_PATIENT_MEDICATION_DATA = 'GET_PATIENT_MEDICATION_DATA';
export const ADD_NEW_PATIENT = 'ADD_NEW_PATIENT';
export const USER_ROLE_SET = "USER_ROLE_SET";
export const GET_PATIENT_HEALTH_DATA = "GET_PATIENT_HEALTH_DATA";
export const GET_ASSIGNED_DATA = "GET_ASSIGNED_DATA";
export const SET_CHAT_CHANNEL = "SET_CHAT_CHANNEL";
export const GET_USER_CHAT = "GET_USER_CHAT";

export const GET_PATIENT_TASK_DATA = "GET_PATIENT_TASK_DATA";
export const GET_PATIENT_PERSONAL_ALLERGY = "GET_PATIENT_PERSONAL_ALLERGY";