import React from 'react';
import '../style.css';

export const Allerges = () => {
    return (
        <div className="medical-no-report">
            <p className="medical-no-report-text">No Reported Allergies</p>
        </div>
    )
}
