import React from 'react'
import '../style.css'
function TaskSectionTail(props) {
    // const { confirmAndSendTask } = props;
    return (
        <div>
            
        </div>
    )
}

export default TaskSectionTail
