import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import "./style.css";
import Moment from "moment";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonUnstyled from "@mui/core/ButtonUnstyled";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import jwt from "jwt-simple";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../config/server";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_PATIENT_MEDICATION_DATA,
  GET_ASSIGNED_DATA,
  GET_USER_CHAT,
} from "../../store/actionNames";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import chroma from "chroma-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import CustomizedDropdown from './freshdropdown';
import CustomizedMultiselect from './freshmultiselect';

let token = localStorage.getItem("token");

const optionsforSeverity = [
  {
    label: 'Mild',
    value: 'Mild',
  },
  {
    label: 'Moderate',
    value: 'Moderate',
  },
  {
    label: 'Severe',
    value: 'Severe',
  },
];

let optionsforStrength = [

];

const ColorButton = styled(ButtonUnstyled)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#610BEF",
  borderRadius: 15,
  width: 300,
  borderColor: "transparent",
  fontFamily: 'Montserrat',
  "&:hover": {
    backgroundColor: "#610BEF",
  },
}));

// const TextArea = styled(textarea)(({ theme }) => ({
//   resize: none,
//   overflowY: hidden,
// }));

// const TextArea = styled.textarea`
//   resize: none,
//   overflow-y:hidden
// `;

//let drugData = [];
let noAllergy = "";
//drugData = [];
let survey_id = "";

let button_text = ["Mark update", "Mark as done", "Mark new"];
const items = [
  { label: "one", value: 1 },
  { label: "two", value: 2 },
];

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white", fontFamily: "Montserrat" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? "red" : "blue",
      color: "#fff",
      cursor: isDisabled ? "not-allowed" : "default",
      fontFamily: "Montserrat"
    };
  },
};



export const Management = ({ roomName, chatInfo, updateReview }) => {
  const [age, setAge] = React.useState("");
  const dispatch = useDispatch();
  const [buttonAction, setButtonAction] = React.useState(true);
  const [state, setState] = useState({});
  const [checkAction1, setCheckAction1] = React.useState("disable-check");//check-active
  const [checkAction2, setCheckAction2] = React.useState("disable-check");
  const [checkAction3, setCheckAction3] = React.useState("disable-check");
  const [checkAction4, setCheckAction4] = React.useState("disable-check");
  const [checkAction5, setCheckAction5] = React.useState("disable-check");
  const [checkAction6, setCheckAction6] = React.useState("disable-check");

  // const [checkAction1, setCheckAction1] = React.useState("check-active");//check-active
  // const [checkAction2, setCheckAction2] = React.useState("check-active");
  // const [checkAction3, setCheckAction3] = React.useState("check-active");
  // const [checkAction4, setCheckAction4] = React.useState("check-active");
  // const [checkAction5, setCheckAction5] = React.useState("check-active");
  // const [checkAction6, setCheckAction6] = React.useState("check-active");

  const [backButtonAction, setBackButtonAction] = React.useState("Back");
  const [buttonDisableAction, setButtonDisableAction] = React.useState(true);
  const [screenAction, setScreenAction] = React.useState(0);
  const [listAction1, setListAction1] = React.useState("");
  const [listAction2, setListAction2] = React.useState("");

  const [medicineName, setMedicineName] = React.useState("");
  const [listAction3, setListAction3] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const [subExpanded, setSubExpanded] = React.useState("panel11");
  const subhandleChange = (panel) => (event, newExpanded) => {
    setSubExpanded(newExpanded ? panel : false);
  };

  const [activeNumber, setActiveNumber] = React.useState(0);

  const [list1, setList1Action] = React.useState("");
  const [list2, setList2Action] = React.useState("");
  const [list3, setList3Action] = React.useState("");
  const [list4, setList4Action] = React.useState("");
  const [select, setSelectAction] = React.useState("");
  const [medicineSave, setMedicineSave] = React.useState(true);
  const [refresh, setRefresh] = React.useState(true);
  const [expandedDescription, setExpandedDescription] = React.useState(true);
  const [patient, setPatient] = React.useState([]);
  const [drugs, setDrugs] = React.useState([]);
  const [allergy, setAllergy] = useState([]);
  const [survey, setSurvey] = useState([]);
  const [surveyAns, setsurveyAns] = React.useState("");
  const [gapsincare, setGapsInCare] = useState([]);
  const [pastVisitSummary, setPastVisitSummary] = useState([]);
  const [afterVisitSummary, setAfterVisitSummary] = useState("");
  const medication = useSelector((state) => state.medication);
  const [chatMedication, setChatMedication] = useState([]);
  const [careGaps, setCareGaps] = useState("");
  const [shortText, setShortText] = useState("");
  const [goalsList, setGoalsList] = useState("");
  // Yevgeniy edited
  const [strengths, setStrengths] = useState([]);
  const [selectedStrength, setSelectStrength] = useState("");
  const [direction, setDirection] = useState("");
  const [surveyEditable, setSurveyButtonState] = React.useState(false);
  const [isUpdatedAfterSummary, setUpdatedAfterSummary] = React.useState(false);
  const [afterSummaryId, setAfterSummaryId] = React.useState(-1);
  const [gapsincarestatus, setGapsInCareStatus] = useState("disable");
  const [markGapsInCare, setMarkGapsInCare] = useState("");
  const [markAfterVisit, setMarkAfterVisit] = useState("");
  const [markPastVisitTxt,setMarkPastVisitTxt] = React.useState("");
  const [afterVisitReview, setAfterVisitReview] = useState("disable");
  const [reviewcount, setReviews] = React.useState(0);
  const [selectedMedication, setSelectedMedication] = React.useState([]);

  const [surveyAnsMakeAsView, setsurveyAnsMakeAsView] = React.useState(false);
  const [markAsSurveyAns, setmarkAsSurveyAns] = React.useState("");
  const [changeStatusvalue, setChangeStatusValue] = React.useState(0);
  const [recommendation_for_resolution, setrecommendation_for_resolution] =
    React.useState("");
  const [care_gaps_as_noted_on_survey, setcare_gaps_as_noted_on_survey] = 
    React.useState([]);
  const [medication_interaction, setmedication_interaction] = 
    React.useState([]);
  const [notes, setNotes] = React.useState("");
  const [markStatus, setMarkStatus] = React.useState(0);

  const [reviewButton, setReviewButton] = React.useState("");
  const [markAsReviewedAllergy, setMarkAsReviewedAllergy] = React.useState("");
  const [medicationButton, setMedicationButton] = React.useState("Save");
  const [markAsReviewedMedication, setMarkAsReviewedMedication] =
    React.useState("");
  const [markAsReviewedMedicationText, setMarkAsReviewedMedicationText] =
    React.useState("");

  const recommendationRef = useRef(null);

  const resizeRecommendationArea = () => {
    recommendationRef.current.style.height = "auto";
    recommendationRef.current.style.height = recommendationRef.current.scrollHeight+2 + "px";
  };

  // -------------------------------------------------
  //   Set Mark as Reviewed and Reset Mark as Reviwed
  // -------------------------------------------------
  // Allergy
  const setAllergyMarkAsReviewed = () => {
    setCheckAction1("check-active");
    setReviewCount(reviewcount + 1);

    let now = Moment(Date.now()).format("MM/DD/YYYY in hh:mm a");
    setMarkAsReviewedAllergy("Last reviewed on " + now);
  }
  const resetAllergyMarkAsReviewed = () => {
    setCheckAction1("disable-check");
    if (markAsReviewedAllergy !== "")
      setReviewCount(reviewcount - 1)
    setMarkAsReviewedAllergy("");
  }
  // Current Medication
  const setCurrentMedicationMarkAsReviewed = () => {
    setCheckAction2("check-active");
    setReviewCount(reviewcount + 1);

    let now = Moment(Date.now()).format("MM/DD/YYYY in hh:mm a");
    setMarkAsReviewedMedicationText("Last reviewed on " + now);
  }
  const resetCurrentMedicationMarkAsReviewed = () => {
    setCheckAction2("disable-check");
    if (markAsReviewedMedicationText !== "")
      setReviewCount(reviewcount - 1)
    setMarkAsReviewedMedicationText("");
  }
  // Survey Answer
  const setSurveyAnswerMarkAsReviewed = () => {
    setCheckAction3("check-active");
    setReviewCount(reviewcount + 1);

    let now = Moment(Date.now()).format("MM/DD/YYYY in hh:mm a");
    setmarkAsSurveyAns("Last reviewed on " + now);
  }
  const resetSurveyAnswerMarkAsReviewed = () => {
    setCheckAction3("disable-check");
    if (markAsSurveyAns !== "")
      setReviewCount(reviewcount - 1)
    setmarkAsSurveyAns("");
  }
  // Past Visit Notes
  const setPastVisitNotesMarkAsReviewed = () => {
    setCheckAction6("check-active");
    setReviewCount(reviewcount + 1);

    let now = Moment(Date.now()).format("MM/DD/YYYY in hh:mm a");
    setMarkPastVisitTxt("Last reviewed on " + now);
  }
  const resetPastVisitNotesMarkAsReviewed = () => {
    setCheckAction6("disable-check");
    if (markPastVisitTxt !== "")
      setReviewCount(reviewcount - 1);
    setMarkPastVisitTxt("");
  }
  // Gaps In Care
  const setGapsInCareMarkAsReviewed = () => {
    setCheckAction4("check-active");
    setReviewCount(reviewcount + 1);

    let now = Moment(Date.now()).format("MM/DD/YYYY in hh:mm a");
    setMarkGapsInCare("Last reviewed on " + now);
  }
  const resetGapsInCareMarkAsReviewed = () => {
    setCheckAction4("disable-check");
    if (markGapsInCare !== "")
      setReviewCount(reviewcount - 1);
    setMarkGapsInCare("");
  }
  // After Visit Summary
  const setAfterVisitSummaryMarkAsReviewed = () => {
    setCheckAction5("check-active");
    setReviewCount(reviewcount + 1);

    let now = Moment(Date.now()).format("MM/DD/YYYY in hh:mm a");
    setMarkAfterVisit("Last reviewed on " + now);
  }
  const resetAfterVisitSummaryMarkAsReviewed = () => {
    setCheckAction5("disable-check");
    if (markAfterVisit !== "")
      setReviewCount(reviewcount - 1);
    setMarkAfterVisit("");
  }
  // -----------------------------------------------
  // -----------------------------------------------

  const update_care_gaps_as_noted_on_survey = () => {
    console.log("updatecaregaps");
    console.log(survey);
    if(survey && survey.length > 0)
    {
      let new_care_gaps_notes = [];
      if(survey[0].answer === "1")
      {
        new_care_gaps_notes.push(survey[0].multiple_options[0]["meaning"]);
      }
      if(survey[1].answer === "3")
      {
        new_care_gaps_notes.push(survey[1].multiple_options[0]["meaning"]);
      }
      if(survey[2].answer === "5")
      {
        new_care_gaps_notes.push(survey[2].multiple_options[0]["meaning"]);
      }
      if(survey[3].answer === "8")
      {
        new_care_gaps_notes.push(survey[0].multiple_options[1]["meaning"]);
      }
      let temp = "";
      if(survey[4].answer === "9" || survey[5].answer === "10")
      {
        temp = "Significant";
      }
      else if (survey[4].answer === "11")
      {
        temp = "Moderate";
      }
      else if(survey[4].answer === "12")
      {
        temp = "Mild";
      }
      if(temp != "")
      {
        new_care_gaps_notes.push("You might be having "+temp+" issues remembering to take your medicine");
      }
      temp = "";
      if(Array.isArray(survey[5].answer))
      {
        survey[5].answer.map(answer_item => {
          if(answer_item === "20")
          {
            temp =  temp + survey[5].multiple_options[0]["meaning"] + ", ";
          }
          else if(answer_item === "21")
          {
            temp = temp + survey[5].multiple_options[1]["meaning"] + ", ";
          }
          else if(answer_item === "22")
          {
            temp = temp + survey[5].multiple_options[2]["meaning"] + ", ";
          }
        })
      }
      if(temp != "")
      {
        new_care_gaps_notes.push("The barriers to getting your medicine include: "+temp);
      }
      if(survey[6].answer === "16")
      {
        new_care_gaps_notes.push(survey[6].multiple_options[0]["meaning"]);
      }
      setcare_gaps_as_noted_on_survey(new_care_gaps_notes);
    }
  }

  useEffect(resizeRecommendationArea, [recommendation_for_resolution]);
  useEffect(update_care_gaps_as_noted_on_survey, [survey]);

  const SurveyanwhandleSelectChange = (value,index) => {
    resetSurveyAnswerMarkAsReviewed();
    setsurveyAnsMakeAsView(false);
    setGapsInCareStatus("enable");
    resetGapsInCareMarkAsReviewed();
    let survey1 = survey;
    survey1[index].answer = value;
    // console.log("survey answer", value);
    setState(survey1);
    update_care_gaps_as_noted_on_survey();
    // console.log(survey);    
  };


  const notesRef = useRef(null);

  const setReviewCount = (count) => {
    setReviews(count);
    updateReview(count);
  }
  const resizeNotesArea = () => {
    notesRef.current.style.height = "auto";
    notesRef.current.style.height = notesRef.current.scrollHeight+2 + "px";
  };

  useEffect(resizeNotesArea, [notes]);
  const handleSelectChange = (value) => {
    // console.log("helloare");
    // console.log(value);
    setAge(value);
    setSelectAction(value);
    let se = value;
    if (list1 !== "" && list2 !== "" && se !== "") {
      setBackButtonAction("Save");
    }
  };

  const handleSearchChange = (event, values) => {
    setMedicineName(values?.label);
    let items = [];
    let se = values?.label;
    if(se!==null && se!==undefined && se!==""){
      axios
      .get(
        `${server.domainURL}/nexpil/drug_product.php?name=${se}&choice=1`
      )
      .then(({data}) => {
        optionsforStrength = [];
        data.forEach( item => {
          items.push(item["Strength"]);
          var selectItem = {
            value: item["Strength"],
            label: item["Strength"]
          }
          optionsforStrength.push(selectItem);
        });
        setStrengths(items);
      })
      .catch(err => {
        optionsforStrength = [];
        setStrengths([]);        
        setExpandedDescription(true);
      });
    }
    else{
      optionsforStrength = [];
      setStrengths([]);
      setExpandedDescription(true);
    }
    // if (list3 !== "" && se !== "") {
    //   setExpandedDescription(false);
    // } else {
    //   setExpandedDescription(true);
    // }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if(!isExpanded) {
      if(panel=="panel1")
      {
        let item = document.getElementById("allergy_div");
        
      }
    }
  };
  const onHandleInputMedicationName = (ev) => {
    const term = ev.target.value;
    //setInfoDetails({ ...infoDetails, task_name: term });
    if (term) {
      axios
        .get(
          `${server.domainURL}/nexpil/drug_product.php?Name=${term}&choice=0`
        )
        .then(({ data }) => {
          let drugData = [];
          data.forEach(element => {
            drugData.push({
              id: element.uid,
              label: element.DrugName
            })
          });

          setDrugs(drugData);
          setRefresh(!refresh);
          //setAvailableMedicationList(data);
        })
        .catch((err) => {});
    }
    else {
      getDrugData();
    }
  };

  const handleAction1 = () => {
    setButtonAction(false);
    setListAction1("active");
    setListAction2("");
    setButtonDisableAction(false);
    setReviewButton("review-button-active");
    noAllergy = (
      <ListItem
        className={`active`}
        key={1}
        disableGutters
        sx={{ borderRadius: 10, backgroundColor: "#EFF0F6", my: 3, py: 1.8 }}
      >
        <IconButton onClick={handleAction1}>
          <Brightness1Icon
            className={`${listAction1} line`}
            sx={{ color: "#DFE6E9" }}
          />
        </IconButton>
        <ListItemText
          className={`${listAction1} line`}
          primary={"No Known Drug Allergies"}
          sx={{ px: 2 }}
        />
      </ListItem>
    );
  };
  const handleAction2 = () => {
    setScreenAction(1);
    setReviewButton("");

    resetAllergyMarkAsReviewed();
    // noAllergy = (
    //   <ListItem
    //     key={1}
    //     disableGutters
    //     sx={{ borderRadius: 10, backgroundColor: "#EFF0F6", my: 3, py:1.8  }}
    //   >
    //     <IconButton onClick={handleAction1}>
    //       <Brightness1Icon
    //         className={`line`}
    //         sx={{ color: "#DFE6E9" }}
    //       />
    //     </IconButton>
    //     <ListItemText
    //       className={` line`}
    //       primary={"No Known Drug Allergies"}
    //       sx={{ px: 2 }}
    //     />
    //   </ListItem>
    // );
  };
  const clickAllergy = (item, id) => {

  }

  const clickCurrentMedication = (item, id) => {
    resetCurrentMedicationMarkAsReviewed();
    var checkitem = document.getElementById("current_medication"+id);
    if (checkitem.className === "active")
    {
      checkitem.className = "deactive";
    }
    else
    {
      checkitem.className = "active";
    }
    setScreenAction(3);
    // setSelectedMedication(selected);
    // setGapsInCareStatus("enable");
  }
  const clickMedication = (item, id) => {
    resetGapsInCareMarkAsReviewed();
    var checkitem = document.getElementById("medication"+id);
    // console.log("hello");
    // console.log(checkitem.className);
    if (checkitem.className === "active")
    {
      checkitem.className = "deactive";
    }
    else
    {
      checkitem.className = "active";
    }
    let selected = [];
    for(var i = 0; i < chatMedication.length; i++)
    {
      var medicationitem = document.getElementById("medication"+i);
      if(medicationitem.className === "active")
      {
        selected.push(i);
      }
    }
    setSelectedMedication(selected);
    setGapsInCareStatus("enable");
  }
  const handleAction3 = () => {
    resetCurrentMedicationMarkAsReviewed();
    setAfterVisitReview("disable");
    resetAfterVisitSummaryMarkAsReviewed();
    setGapsInCareStatus("enable");
    resetGapsInCareMarkAsReviewed();
    setScreenAction(1);
  };
  const SaveOrBack = (e) => {
    let l1 = list1;
    let l2 = list2;
    if (e.target.id === "list1") {
      setList1Action(e.target.value);
      l1 = e.target.value;
    } else if (e.target.id === "list2") {
      setList2Action(e.target.value);
      l2 = e.target.value;
    }

    if (l1 !== "" && l2 !== "" && select !== "") {
      setBackButtonAction("Save");
    } else if ((screenAction === 1 && l1 === "") || l2 === "" || select !== "") {
      setBackButtonAction("Back");
    } else if ((screenAction === 2 && l1 === "") || l2 === "" || select !== "") {
      setBackButtonAction("Done");
    }
  };
  const ShowDescription = (e) => {
    setMedicationButton("Save");
    if (e.target.value !== "")
    {
      setSelectStrength(e.target.value);
      setExpandedDescription(false);
    }
    else {
      setExpandedDescription(true);
    }
  };
  const completeAllergy = async (e) => {
    if (backButtonAction === "Save") {
      let allergyData = {
        list1: list1,
        list2: list2,
        select: select,
        patient_id: patient.id,
        appointment_id: patient.appointmentId,
      };

      // eslint-disable-next-line
      var temp = await axios
        .post(server.serverURL + "v1/add-allergy", allergyData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {});
      let tempID = [];
      tempID = {
        selPatient: patient.id,
      };
      getAllergy(tempID,patient.appointmentId);
      // setCheckAction1("check-active");
      // setExpanded(false);
      // setScreenAction(true);
      setListAction1("");
      setListAction2("");
      setList1Action("");
      setList2Action("");
      setList3Action("");
      setBackButtonAction("Done");

      setButtonAction(true);
      //setActiveNumber(activeNumber+1);
    } else if (e.target.innerText === "Back") {
      setListAction2("");
      if (checkAction1 != "check-active") {
        noAllergy = (
          <ListItem
            key={1}
            disableGutters
            sx={{ borderRadius: 6, backgroundColor: "#EFF0F6", my: 3, py: 1.8 }}
          >
            <IconButton onClick={handleAction1}>
              <Brightness1Icon className={` line`} sx={{ color: "#DFE6E9" }} />
            </IconButton>
            <ListItemText
              className={` line`}
              primary={"No Known Drug Allergies"}
              sx={{ px: 2 }}
            />
          </ListItem>
        );
      }
      setScreenAction(0);
    } else if (e.target.innerText === "Done") {
      setScreenAction(0);
      setReviewButton("review-button-active");
    } else if (screenAction === 0 && e.target.innerText === "Mark As Reviewed") {
      setAllergyMarkAsReviewed();

      setExpanded(false);
      setScreenAction(0);
      setListAction1("");
      setListAction2("");
      setList1Action("");
      setList2Action("");
      setList3Action("");
      setBackButtonAction("Done");

      setButtonAction(true);
    }
  };
  const markasreviewSurvey = async (e)=>{
    setSurveyAnswerMarkAsReviewed();
  }
  const completeSurvery = async (e) =>{
    if(survey && survey.length > 0){
      //processing survey answers for care gaps note
      update_care_gaps_as_noted_on_survey();
      console.log("complete survey");
      console.log(survey);
      survey.map(async (item,index)=>{
        var surveydata = {
          patient_id: patient.id,
          survey_question_id:item.id,
          multiple_type_id:item.multiple_type_id,
          appointment_id: patient.appointmentId,
          answer:item.answer,
        };
        console.log(surveydata);
          var temp = await axios
          .post(server.domainURL +
            "webapi/v1/survey-questions-update", surveydata, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
          })
          .then(function (response) {
            console.log("survery response");
            console.log(response);
          });
      });
    }
    setsurveyAnsMakeAsView(true);
    //setCheckAction3("check-active");
    //state["surveyAns_"+index]
  }
  const removeMedicine = async (e) => {
    // implement remove medicines api here
    let selected = [];
    let new_medicinearray = [];
    for(var i = 0; i < chatMedication.length; i++)
    {
      var medicationitem = document.getElementById("current_medication"+i);
      if(medicationitem.className === "deactive")
      {
        selected.push(`${chatMedication[i].id}`);
      }
      else{
        new_medicinearray.push(chatMedication[i]);
      }
    }
    
    var payload = {
      appointment_id: patient.appointmentId,
      removal_items: selected,
    }
    console.log("removal_medication", payload);
    await axios
      .post(server.serverURL + "v1/remove-medication", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        dispatch({ type: GET_PATIENT_MEDICATION_DATA, payload: patient.id ,appointId : patient.appointmentId});
        setChatMedication(new_medicinearray);
        setScreenAction(0);
        setMarkAsReviewedMedication("MarkAsReview");
        resetGapsInCareMarkAsReviewed();
        resetAfterVisitSummaryMarkAsReviewed();
      });
  }
  const completeMedicine = async (e) => {
    if (e.target.innerText === "Save") {
      // const params = JSON.stringify({
      //   "label_text": direction
      // });
      // await axios
      //   .post("https://ttjhz8fwrl.execute-api.us-east-2.amazonaws.com/v1/nextpill_comp_api",
      //     params,
      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //         "Access-Control-Allow-Origin": "*",
      //         "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
      //         "crossDomain": true,
      //         "Access-Control-Allow-Credential": true,
      //         "mode": "cors"
      //       },
      //     })
      //   .then(data => {
      //     console.log("checkmedicine");
      //     console.log(data);
      //   });
      
      let medicineData = {
        list3: selectedStrength,
        list4: direction,
        medicineName: medicineName,
        patient_id: patient.id,
        appointment_id: patient.appointmentId,
      };

      var temp = await axios
        .post(server.serverURL + "v1/add-medicine", medicineData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          // setCheckAction2("check-active");
          // setExpanded(false);
          // setScreenAction(1);
          setListAction3("");
          setSelectStrength("");
          setDirection("");
          // setMedicineName("");
          setMedicationButton("Done");
          dispatch({ type: GET_PATIENT_MEDICATION_DATA, payload: patient.id ,appointId : patient.appointmentId});
        });
    } else if (e.target.innerText === "Done") {
      setScreenAction(0);
      setMarkAsReviewedMedication("MarkAsReview");
    } else if (screenAction === 0 && e.target.innerText === "Mark As Reviewed") {
      setCurrentMedicationMarkAsReviewed();
    }
  };
  const noAllergyComplete = () => {
    setCheckAction1("check-active");
    // setExpanded(false);
    // setScreenAction(1);
    setListAction1("");
    setListAction2("");
    setButtonAction(true);
    //setActiveNumber(activeNumber+1);
    setAllergyMarkAsReviewed();
  };
  useEffect(() => {
    let data = jwt.decode(chatInfo, "xxx");
    let patient = data.userInfo;
    dispatch({ type: GET_PATIENT_MEDICATION_DATA, payload: patient.id ,appointId : patient.appointmentId});
    setPatient(patient);
    let tempID = [];
    tempID = {
      selPatient: patient.id,
    };

    getDrugData();
    getAllergy(tempID,patient.appointmentId);

    // get_survey_id(patient.id)
    getMedicationData(medication);
    get_survey_info(patient.id,patient.appointmentId);
    get_gapincare_info(patient.id,patient.appointmentId);
    get_past_visit_summary(patient.id);
    get_after_visit_summary(patient.id, patient.appointmentId);
    ////markAsReviewed();
  }, [chatInfo, medication.length, changeStatusvalue]);

  useEffect(() => {
    markAsReviewed();
  }, [checkAction1, checkAction2, checkAction3, checkAction4, checkAction5, checkAction6]);

  const getMedicationData = (data) => {
    let array = [];
    // if (data.length !== 0 && data.length > 4) {
    //   for (let index = 0; index < 4; index++) {
    //     const element = data[index];
    //     element ? array.push(element["title"]) : array.push("");
    //   }
    //   setChatMedication(array);
    //   setCheckAction2("check-active");
    // } else if (data.length !== 0 && data.length <= 4) {
    //   for (let index = 0; index < data.length; index++) {
    //     const element = data[index];
    //     element ? array.push(element["title"]) : array.push("");
    //   }
    //   setChatMedication(array);
    //   setCheckAction2("check-active");
    // } else {
    //   setChatMedication([]);
    // }
    if (data.length !== 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const item = { id:element["id"], direction: element["directions"], strength: element["strength"], medicationname: element["medicationname"]};
        element ? array.push(item) : array.push("");
      }
      setChatMedication(array);
      //setCheckAction2("check-active");
    }
    else {
      setChatMedication([]);
    }
  };
  const getDrugData = async () => {
    let drugData = [];
    var temp = await axios
      .get(server.serverURL + "v1/getDrug", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        response.data.data.map((item, index) => {
          drugData.push({
            label: response.data.data[index]["brand_name"],
            id: response.data.data[index]["id"]
          });
        });
        setDrugs(drugData);
        setRefresh(!refresh);
      });
  };
  const getAllergy = async (ID,AppointID) => {
    let patient = {
      patientID: ID,
      appointment_id: AppointID
    };
    var temp = await axios
      .post(server.serverURL + "v1/allergy", patient, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setAllergy(response.data.data);
        if (screenAction === 0 && response.data.data.length != 0) {
          //setCheckAction1("check-active");
        }
        if (response.data.data.length === 0) {
          noAllergy = (
            <ListItem
              className={`${listAction1}`}
              key={1}
              disableGutters
              sx={{
                borderRadius: 6,
                backgroundColor: "#EFF0F6",
                my: 3,
                py: 1.8,
              }}
            >
              <IconButton onClick={handleAction1}>
                <Brightness1Icon
                  className={`${listAction1} line`}
                  sx={{ color: "#DFE6E9" }}
                />
              </IconButton>
              <ListItemText
                className={`${listAction1} line`}
                primary={"No Known Drug Allergies"}
                sx={{ px: 2 }}
              />
            </ListItem>
          );
        } else {
          // setCheckAction1("check-active");
          noAllergy = "";
        }

        //markAsReviewed();
      });
  };
  const setSurveryData= async (midData)=>{
    if(midData && midData.length > 0){
      midData.map((item,index)=>{
        if(item.multiple_type_id === 2){
          setState({ ...state, ["surveyAns_"+index]: item.answer});  
        }
        else {
          setState({ ...state, ["surveyAns_"+index]: item.multiple_type_id});  
        }
      });
    }
  }
  const get_survey_info = async (patient_id,appointmentId) => {
    await axios
      .get(
        server.domainURL +
          "webapi/v1/survey-questions?patient_id=" +
          patient_id +"&appointment_id="+appointmentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        // setCheckAction3("check-active");
        try{
          setSurvey(response.data.data);
          setSurveryData(response.data.data);
        } catch (err) {
          
        }
        //markAsReviewed();
      });
  };

  const textedithandler = (e) => {
    if (e.target.id === "textedit") setCareGaps(e.target.value);
    else if (e.target.id === "recommendation_edit") setrecommendation_for_resolution(e.target.value);
    else if (e.target.id === "notes_edit") setNotes(e.target.value);
    else if (e.target.id === "goalsList") {
      setGoalsList(e.target.value);
    }
  };
  const gapsincareedit = (e) => {
    // console.log("abc");
    resetGapsInCareMarkAsReviewed();
    setGapsInCareStatus("enable");
  }
  const get_gapincare_info = async (id, appointId) => {
    await axios
      .get(server.serverURL + "v1/gaps-in-care/" + id + "/" + appointId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        if (response.data) {
          let tempGapsInCare = response.data.data;
          if(tempGapsInCare !== null && tempGapsInCare !== undefined && tempGapsInCare !== [] && tempGapsInCare !== "")
          {
            setGapsInCare(tempGapsInCare);
            console.log("tempgapsincare",tempGapsInCare);
            setrecommendation_for_resolution(tempGapsInCare["recommendation_for_resolution"]);
            // if(!Array.isArray(tempGapsInCare["care_gaps_as_noted_on_survey"]))
            //   setcare_gaps_as_noted_on_survey([]);
            // else
            //   setcare_gaps_as_noted_on_survey(tempGapsInCare["care_gaps_as_noted_on_survey"]);
            
            console.log("care_gaps_notes", care_gaps_as_noted_on_survey);
            // setAfterVisitSummary({
            //   ...afterVisitSummary,
            //   recommendation_for_resolution: tempGapsInCare["recommendation_for_resolution"],
            //   notes: tempGapsInCare["notes"],
            //   medications: chatMedication,
            //   reviewed_date: Date.now()
            // });            
            //setCheckAction4("disable-check");
            setNotes(tempGapsInCare["notes"]);
            setGapsInCareStatus("disable");
          }
          else
          {
            //setCheckAction4("disable-check");
            setGapsInCare([]);
            setrecommendation_for_resolution("");
            setNotes("");
            //setcare_gaps_as_noted_on_survey([]);
            setGapsInCareStatus("enable");
          }
        }
        else{
          //setCheckAction4("disable-check");
          setGapsInCare([]);
          setrecommendation_for_resolution("");
          setNotes("");
          //setcare_gaps_as_noted_on_survey([]);
          setGapsInCareStatus("enable");
        }
        //markAsReviewed();
      });
  };
  const get_past_visit_summary = async (patientId) => {
    await axios
      .get(server.serverURL + "v1/visit-summaries?patient_id=" + patientId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        console.log(localStorage);
        if(response.data.data !== null && response.data.data !== undefined && response.data.data !== "" && response.data.data !== [])
        {
          setPastVisitSummary(response.data.data);
          let tempData = [];
          tempData = response.data.data.filter(
            (item, index) =>
              item["doctor_info"]["id"] === localStorage.getItem("userId")
          );
          // if(response.data.data.length>0)
            //setCheckAction6("check-active");
        }
        else
        {
          setPastVisitSummary([]);
          resetPastVisitNotesMarkAsReviewed();
        }
        //markAsReviewed();
      });
  };
  const get_after_visit_summary = async (patientId, appointmentId) => {
    let short_text = "we comprehensively reviewed your medication for safety and discussed how well it is meeting your needs.";
    let goals = [
      "Identify any medication interactions",
      "Evaluate any problems you might be having with your medications.",
      "Determine if you had any issues getting your medications or taking your medications",
      "Review your medication regemen and optimize it for needs"
    ];
    await axios
      .get(server.serverURL + "v1/visit-summary/" + appointmentId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        if(response.data.data !== null && response.data.data !== undefined && response.data.data !== "" && response.data.data !== [])
        {
          if(response.data.data.length === 0)
          {
            let summary = {
              reviewed_date: Date.now(),
              goals: goals,
              notes: "",
              recommendation_for_resolution: "",
              care_gaps_as_noted_on_survey: [],
              medications: [],
              medication_interaction: ["We have found no interaction between all medications"],
              short_text: short_text,
            }
            setAfterVisitSummary(summary);
            setCheckAction5("disable-check");
            resetAfterVisitSummaryMarkAsReviewed();
          }
          else
          {
            setAfterVisitSummary(response.data.data);
            resetAfterVisitSummaryMarkAsReviewed();
            setAfterSummaryId(response.data.data.id);
            //setCheckAction5("check-active");
            setUpdatedAfterSummary(true);
          }
        }
        else{
          let summary = {
            reviewed_date: Date.now(),
            goals: goals,
            notes: "",
            recommendation_for_resolution: "",
            medications: [],
            care_gaps_as_noted_on_survey: [],
            medication_interaction: ["We have found no interaction between all medications"],
            short_text: short_text
          }
          setAfterVisitSummary(summary);
          resetAfterVisitSummaryMarkAsReviewed();
          setCheckAction5("disable-check");
        }
      })
      .catch((err) => {
        let summary = {
          reviewed_date: Date.now(),
          goals: goals,
          notes: "",
          recommendation_for_resolution: "",
          medications: [],
          care_gaps_as_noted_on_survey: [],
          medication_interaction: ["We have found no interaction between all medications"],
          short_text: short_text
        }
        setAfterVisitSummary(summary);
        resetAfterVisitSummaryMarkAsReviewed();
        setCheckAction5("disable-check");
      });
  }
  const showSave = (e) => {
    setMedicationButton("Save");
    let direction = e.target.value;
    setDirection(direction);
    if (direction !== "") {
      setMedicineSave(false);
    }
    else{
      setMedicineSave(true);
    }
  };
  const surveryAnsText =(e,name) =>{
    setsurveyAnsMakeAsView(false);
    resetSurveyAnswerMarkAsReviewed();
    //setGapsInCareStatus("enable");
    setState({ ...state, [name]: e.target.value });    
  }
  const formgaps = (e) => {
    if (e.target.name === "recommendation") {
      setrecommendation_for_resolution(e.target.value);
    } else if (e.target.name === "notes") {
      setNotes(e.target.value);
    } else if (e.target.name === "short_text") {
      setShortText(e.target.value);
    }
  };
  const save_gaps_info = () => {
    var text = careGaps;
    var lines = text.split("\n");
    // let medicationarray = []
    // medication.map((item) => {
    //   let temp = {"id":item.id}
    //   medicationarray.push(temp)
    // })
    let medicationarray = [];
    let medicationinfos = [];
    if(selectedMedication.length === 0)
    {
      medication.map((item) => {
        let temp = {"id":item.id}
        medicationarray.push(temp)
        medicationinfos.push(item);
      })
    }
    else
    {
      for(var i = 0; i < selectedMedication.length; i++)
      {
        var index = selectedMedication[i];
        var item = chatMedication[index];
        let medication = {
          medicationname: item["medicationname"],
          strength: item["strength"],
          directions: item["direction"]
        };
        let temp = {"id":item.id};
        medicationarray.push(temp);
        medicationinfos.push(medication);
      }
    }

    // medication interaction code
    let interactions_array = [];
    if (medicationinfos.length >= 2)
    {
      interactions_array.push("We found that you had " + 1 + " interaction between " + medicationinfos[0].medicationname +" and " + medicationinfos[medicationinfos.length-1].medicationname+".");
    }
    else{
      interactions_array.push("We found that you had no interaction between all medications.");
    }
    setmedication_interaction(interactions_array);
    let gapdata = {};
    gapdata = {
      patient_id: patient.id,
      appointment_id: patient.appointmentId,
      medications: medicationinfos,
      recommendation_for_resolution: recommendation_for_resolution,
      care_gaps_as_noted_on_survey: care_gaps_as_noted_on_survey,
      medication_interaction: interactions_array,
      notes: notes,
    };
    axios
      .post(server.serverURL + "v1/gaps-in-care", gapdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        //must be changed with response
        //setrecommendation_for_resolution(recommendation_for_resolution);
        //setNotes(notes);
        //get_gapincare_info(patient.id, patient.appointmentId);
        let short_text = "we comprehensively reviewed your medication for safety and discussed how well it is meeting your needs.";
        let goals = [
          "Identify any medication interactions",
          "Evaluate any problems you might be having with your medications.",
          "Determine if you had any issues getting your medications or taking your medications",
          "Review your medication regemen and optimize it for needs"
        ];

        let summary = {
          recommendation_for_resolution: recommendation_for_resolution,
          short_text: short_text,
          notes: notes,
          reviewed_date: Date.now(),
          medications: medicationinfos,
          care_gaps_as_noted_on_survey: care_gaps_as_noted_on_survey,
          medication_interaction: interactions_array,
          goals: goals
        }
        setAfterVisitSummary(summary);
        setAfterVisitReview("disable");
        resetAfterVisitSummaryMarkAsReviewed();
        //setChangeStatusValue(Math.random());
        setMarkStatus(0);
        setGapsInCareStatus("disable");
        //setCheckAction4("check-active");
      });
  };
  const save_after_visit_summary = () => {
    var text = goalsList;
    var lines = text.split("\n");
    let medicationarray = [];
    if(selectedMedication.length === 0)
    {
      chatMedication.map((item) => {
        let temp = {"id":item.id}
        medicationarray.push(`${item.id}`)
      })
    }
    else
    {
      for(var i = 0; i < selectedMedication.length; i++)
      {
        var index = selectedMedication[i];
        var item = chatMedication[index];
        medicationarray.push(`${item.id}`);
      }
    }
    // chatMedication.map((item) => {
    //   let medication = item.id;//{medication_id: item.id, medicationname: item["medicationname"], strength: item["strength"], directions: item["direction"]};
    //   medicationarray.push(`${medication}`);
    // });
    let short_text = "we comprehensively reviewed your medication for safety and discussed how well it is meeting your needs.";
    let goals = [
      "Identify any medication interactions",
      "Evaluate any problems you might be having with your medications.",
      "Determine if you had any issues getting your medications or taking your medications",
      "Review your medication regemen and optimize it for needs"
    ];
    let summaryData = {
      patient_id: `${patient.id}`,
      doctor_id: localStorage.userId,
      appointment_id: `${patient.appointmentId}`,
      medications: medicationarray,
      recommendation_for_resolution: recommendation_for_resolution,
      care_gaps_as_noted_on_survey: care_gaps_as_noted_on_survey,
      medication_interaction: medication_interaction,
      notes: notes,
      short_text: short_text,
      goals: goals
    };

    //setCheckAction5("check-active");
    //if (afterVisitSummary !== undefined && afterVisitSummary !== "" && afterVisitSummary !== null) {
    if(!isUpdatedAfterSummary)  
    {
      axios
      .post(server.serverURL + "v1/visit-summaries", summaryData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setAfterSummaryId(res.data['GapsInCareId']);
        // setChangeStatusValue(Math.random());
        setMarkStatus(0);
        setAfterVisitReview("enable");
      })
      .catch(err => {
        setUpdatedAfterSummary(false);
      });
    }
    else
    {
      axios
      .put(server.serverURL + "v1/visit-summaries/"+afterSummaryId, summaryData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // setChangeStatusValue(Math.random());
        setMarkStatus(0);
        setAfterVisitReview("enable");
      })
      .catch(err => {

      })
    }
    //}
  };
  const changeStatus = () => {
    if (markStatus === 0) setMarkStatus(1);
    else setMarkStatus(0);
  };

  const save = (data) => {
    
  };
  const markasPastVisitNote =()=>{
    setPastVisitNotesMarkAsReviewed();
  }
  const markasGapsInCare = () => {
    setGapsInCareMarkAsReviewed();
  }

  const markasAfterVisit = () => {
    setAfterVisitSummaryMarkAsReviewed();
  }
  const markAsReviewed = () => {
    let counter = 0;
    
    if(checkAction1 === "check-active"){
      counter++;
    }
    if(checkAction2 === "check-active"){
      counter++;
    }
    if(checkAction3 === "check-active"){
      counter++;
    }
    if(checkAction4 === "check-active"){
      counter++;
    }
    if(checkAction5 === "check-active"){
      counter++;
    }
    if(checkAction6 === "check-active"){
      counter++;
    }
    
    setActiveNumber(counter);
  }

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });
  return (
    <div
      className="room d-flex justify-content-center flex-column py-4 px-3"
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div>
        <div className="d-flex justify-content-between p-3">
          <div className="checked-title">
            Medication Therapy 
            <br /> Management Tasks
          </div>
          <div className="checked-info">
            {activeNumber} out of 6<br /> Completed
          </div>
        </div>
      </div>

      <div className="accordation" id="allergy_div">
        <div className="d-flex">
          <CheckCircleIcon
            className={`${checkAction1}`}
            style={{ marginRight: "2px", marginTop: "28px"}}
          />
          <Accordion
            className="w-100"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              py: 0.4,
              my: 0.8,
              backgroundColor: "#F7F7FC",
              borderRadius: 4,
              boxShadow: 0,
            }}
            style={{width: "30%"}}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600, fontWeight: "600!important", fontFamily: "Montserrat!important", color: "#14142B", marginTop: "15px"  }}>
                Allergies
              </Typography>
              {/* <div
                    className="text-center"
                    style={{
                      color: "#610BEF",
                      marginTop: 45,
                      marginBottom: 6,
                      fontWeight: 700,
                      fontFamily: 'Montserrat'
                    }}
                  >{markAsReviewedAllergy !== undefined && markAsReviewedAllergy !== null && markAsReviewedAllergy !=="" ? markAsReviewedAllergy:""}
                  </div> */}
            </AccordionSummary>

            <AccordionDetails>
              {screenAction === 0 && (
                <div>
                  <List sx={{ width: "100%", bgcolor: "#F7F7FC" }}>
                    {allergy.map((item, i) => (
                      <ListItem
                        key={i}
                        disableGutters
                        sx={{
                          borderRadius: 5,
                          backgroundColor: "#610BEF",
                          my: 3,
                          py: 0.5,
                        }}
                      >
                        <IconButton onClick={clickAllergy(item, i)}>
                          <div className="active" style={{marginLeft:"5px", width:"15px", height:"15px"}}>

                          </div>
                          <CheckBoxIcon
                            defaultChecked
                            className=""
                            sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                          />
                        </IconButton>
                        <ListItemText
                          className="active"
                          secondary={
                            <React.Fragment>
                              <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>
                                {item.allergy_name} {" - "} {item.interaction} {" , "} {item.allergy_type}
                              </small>
                              <br/>
                            </React.Fragment>
                            }
                          sx={{ px: 2, color: "#FFFFFF" }}
                          style={{paddingTop: "10px", paddingBottom: "10px"}}
                        />
                      </ListItem>
                    ))}
                    {noAllergy}
                    <ListItem
                      className={`${listAction2}`}
                      key={2}
                      disableGutters
                      sx={{
                        borderRadius: 5,
                        backgroundColor: "#EFF0F6",
                        mt: 10,
                        py: 1.8,
                      }}
                    >
                      <IconButton onClick={handleAction2}>
                        <Brightness1Icon
                          className={`${listAction2} line`}
                          sx={{ color: "#DFE6E9" }}
                        />
                      </IconButton>
                      <ListItemText
                        className={`${listAction2} line`}
                        primary={"Add an Allergy"}
                        sx={{ px: 2 }}
                      />
                    </ListItem>
                  </List>
                  {
                    <div>
                      <div
                        className="text-center"
                        style={{
                          color: "#610BEF",
                          marginTop: 45,
                          marginBottom: 6,
                          fontWeight: 700,
                          fontFamily: 'Montserrat'
                        }}
                      >
                        {markAsReviewedAllergy}
                      </div>
                      <ColorButton
                        className="w-100"
                        variant="contained"
                        onClick={noAllergyComplete}
                        style={{
                          float: "right",
                          marginBottom: 12,
                          paddingBottom: 16,
                          paddingTop: 16,
                          borderRadius: 32,
                        }}
                      >
                        Mark As Reviewed
                      </ColorButton>
                    </div>
                  }
                </div>
              )}
              {screenAction === 1 && (
                <div>
                  {allergy.map((item, i) => (
                    <ListItem
                      key={i}
                      disableGutters
                      sx={{
                        borderRadius: 4,
                        backgroundColor: "#610BEF",
                        my: 2,
                        py: 1.0,
                      }}
                    >
                      <IconButton onClick={handleAction1}>
                        <div className="active" style={{marginLeft:"5px", width:"15px", height:"15px"}}>
                        </div>
                        <CheckBoxIcon
                          defaultChecked
                          className=""
                          sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                        />
                      </IconButton>
                      <ListItemText
                        className="active"
                        primary={`${item.allergy_name} - ${item.interaction} ${item.allergy_type}`}
                        sx={{ px: 2, color: "#FFFFFF" }}
                      />
                    </ListItem>
                  ))}
                  <input
                    id="list1"
                    value={list1}
                    type="text"
                    placeholder="Allergy"
                    className="w-100 login-form"
                    onChange={SaveOrBack}
                    style={{ margin: 5 }}
                  />
                  <input
                    id="list2"
                    value={list2}
                    type="text"
                    placeholder="Reaction"
                    className="w-100 login-form"
                    onChange={SaveOrBack} 
                    style={{ margin: 5 }}
                  />
                  <CustomizedDropdown placeholder="Severity" value={age} options={optionsforSeverity} style={{marginLeft: "5px", marginTop: "5px"}} onSelect={handleSelectChange}/>
                  <ColorButton
                    className="w-25"
                    variant="contained"
                    onClick={completeAllergy}
                    style={{
                      float: "right",
                      marginTop: 12,
                      marginBottom: 12,
                      paddingBottom: 16,
                      paddingTop: 16,
                      borderRadius: 32,
                    }}
                  >
                    {backButtonAction}
                  </ColorButton>
                  <div style={{marginTop:"150px"}}>
                    <ColorButton
                      className="w-100"
                      variant="contained"
                      disabled
                      onClick={noAllergyComplete}
                      style={{
                        float: "right",
                        marginBottom: 12,
                        paddingBottom: 16,
                        paddingTop: 16,
                        borderRadius: 32,
                        backgroundColor: "#EFF0F6",
                        color: "#707070"
                      }}
                    >
                      Mark As Reviewed
                    </ColorButton>
                  </div>
                </div>
              )}
              {screenAction === 2 && (
                <div>
                  {allergy.map((item, i) => (
                    <ListItem
                      key={i}
                      disableGutters
                      sx={{
                        borderRadius: 4,
                        backgroundColor: "#610BEF",
                        my: 2,
                        py: 1.0,
                      }}
                    >
                      <IconButton onClick={handleAction1}>
                        <div className="active" style={{marginLeft:"5px", width:"15px", height:"15px"}}>

                        </div>
                        <CheckBoxIcon
                          defaultChecked
                          className=""
                          sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                        />
                      </IconButton>
                      <ListItemText
                        className="active"
                        primary={`${item.allergy_name} - ${item.interaction} ${item.allergy_type}`}
                        sx={{ px: 2, color: "#FFFFFF" }}
                      />
                    </ListItem>
                  ))}
                  <div
                    className="text-center"
                    style={{
                      color: "#610BEF",
                      marginTop: 45,
                      marginBottom: 6,
                      fontWeight: 700,
                      fontFamily: 'Montserrat'
                    }}
                  >{`Last reviewed on ${Moment(markAsReviewedAllergy).format(
                    "MM/DD/YYYY in hh:mm a"
                  )}`}</div>
                  <ColorButton
                    className="w-100"
                    variant="contained"
                    onClick={completeAllergy}
                    style={{
                      float: "right",
                      marginBottom: 12,
                      paddingBottom: 16,
                      paddingTop: 16,
                      borderRadius: 32,
                    }}
                  >
                    Mark As Reviewed
                  </ColorButton>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="d-flex">
          <CheckCircleIcon
            className={checkAction2}
            style={{ marginRight: "2px", marginTop: "28px"}}
          />
          <Accordion
            className="w-100"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              py: 0.4,
              my: 0.8,
              backgroundColor: "#F7F7FC",
              borderRadius: 5,
              boxShadow: 0,
            }}
            style={{width: "30%"}}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600, fontWeight: "600!important", fontFamily: "Montserrat!important", color: "#14142B",marginTop: "15px"   }}>
                Current Medications
              </Typography>
              {/* <div
                className="text-center"
                style={{
                  color: "#610BEF",
                  marginTop: 45,
                  marginBottom: 6,
                  fontWeight: 700,
                  fontFamily: 'Montserrat'
                }}
              >
                {markAsReviewedMedicationText !== undefined && markAsReviewedMedicationText !== null && markAsReviewedMedicationText !=="" ? markAsReviewedMedicationText : ""}
              </div> */}
            </AccordionSummary>
            <AccordionDetails>
              {chatMedication.map((item, i) => (
                <ListItem
                  key={i}
                  disableGutters
                  sx={{
                    borderRadius: 4,
                    backgroundColor: "#610BEF",
                    my: 3,
                    py: 0.5,
                  }}
                >
                  <IconButton onClick={(e) => clickCurrentMedication(item, i)} >
                    <div className="active" id={"current_medication"+i}  style={{marginLeft:"5px", width:"15px", height:"15px"}}>

                    </div>
                    <CheckBoxIcon
                      className=""
                      sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                    />
                  </IconButton>
                  <ListItemText
                    className="active"
                    secondary={
                      <React.Fragment>
                        <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["medicationname"]+" - "+item["strength"]}</small>
                        <br/>
                        <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["direction"]}</small>
                      </React.Fragment>
                    }
                    sx={{ px: 2, color: "#FFFFFF" }}
                  />
                  {/* <p className="active" key={i}>{item}</p> */}
                </ListItem>
              ))}
              {screenAction === 0 && (
                <div>
                  <ListItem
                    className={`${listAction3}`}
                    key={5}
                    disableGutters
                    sx={{ borderRadius: 10, backgroundColor: "#EFF0F6", my: 3, fontFamily: "Montserrat" }}
                  >
                    <IconButton onClick={handleAction3}>
                      <Brightness1Icon
                        className={listAction3}
                        sx={{ color: "#DFE6E9" }}
                      />
                    </IconButton>
                    <ListItemText
                      className={`${listAction3}`}
                      primary={"Add an Medication"}
                      sx={{ px: 2, fontFamily: "Montserrat" }}
                    />
                  </ListItem>
                </div>
              )}
              {
                screenAction === 3 && (
                  <div>
                    <ListItem
                      className={`${listAction3}`}
                      key={5}
                      disableGutters
                      sx={{ borderRadius: 10, backgroundColor: "#EFF0F6", my: 3, fontFamily: "Montserrat" }}
                    >
                      <IconButton onClick={handleAction3}>
                        <Brightness1Icon
                          className={listAction3}
                          sx={{ color: "#DFE6E9" }}
                        />
                      </IconButton>
                      <ListItemText
                        className={`${listAction3}`}
                        primary={"Add an Medication"}
                        sx={{ px: 2, fontFamily: "Montserrat" }}
                      />
                    </ListItem>
                  </div>
                )
              }
              {screenAction === 3 && (
                  <ColorButton
                    className="w-25"
                    //hidden={medicineSave}
                    variant="contained"
                    onClick={removeMedicine}
                    style={{
                      float: "right",
                      marginTop: 12,
                      marginBottom: 12,
                      paddingBottom: 12,
                      paddingTop: 12,
                      borderRadius: 32,
                      fontFamily: "Montserrat"
                    }}
                  >
                    Save
                  </ColorButton>
                )
              }
              {screenAction === 0 &&
                (
                  <div>
                    {markAsReviewedMedicationText !== undefined && markAsReviewedMedicationText !== null && markAsReviewedMedicationText !=="" && 
                    (
                      <div
                      className="text-center"
                      style={{
                        color: "#610BEF",
                        marginTop: 45,
                        marginBottom: 6,
                        fontWeight: 700,
                        fontFamily: 'Montserrat'
                      }}
                      >{markAsReviewedMedicationText}</div>
                    )}
                    <ColorButton
                      className="w-100"
                      variant="contained"
                      onClick={completeMedicine}
                      style={{
                        float: "right",
                        marginBottom: 12,
                        paddingBottom: 16,
                        paddingTop: 16,
                        borderRadius: 32,
                        fontFamily: "Montserrat"
                      }}
                    >
                      Mark As Reviewed
                    </ColorButton>
                  </div>
                )}
              {screenAction === 1 && (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={drugs}
                      sx={{ width: "60%", backgroundColor: "#EFF0F6" }}
                      onChange={handleSearchChange}
                      onInputChange={onHandleInputMedicationName}
                      renderInput={(params) => (
                        <TextField {...params} label="Medication name" />
                      )}
                      style={{
                        border: "2 solid transparent",
                        boxShadow: 0,
                        borderRadius: 24,
                        fontFamily: "Montserrat"
                      }}
                    />
                    <Box sx={{ minWidth: 120, width: "35%"}}>
                      {/* <CustomizedDropdown placeholder="Strength" options={optionsforStrength} onSelect={ShowDescription} /> */}
                      {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Strength</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="select_strength"
                          value={selectedStrength}
                          onChange={ShowDescription}
                          //style={colourStyles}
                        >
                          {
                            strengths.map((item, id) => {
                              return(<MenuItem key={id} value={item}>{item}</MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Strength</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="select_strength"
                          value={selectedStrength}
                          label="Strength"
                          onChange={ShowDescription}
                          sx={{backgroundColor: "#EFF0F6" }}
                        >
                          {
                            strengths.map((item, id) => {
                              return(<MenuItem key={id} value={item}>{item}</MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div hidden={expandedDescription}>
                    <input
                      id="direction"
                      value={direction}
                      style={{ marginTop: 7, fontFamily: "Montserrat", backgroundColor: "#EFF0F6" }}
                      type="text"
                      placeholder="Directions"
                      className="w-100 login-form"
                      onChange={showSave}
                    />
                    <ColorButton
                      className="w-25"
                      hidden={medicineSave}
                      variant="contained"
                      onClick={completeMedicine}
                      style={{
                        float: "right",
                        marginTop: 12,
                        marginBottom: 12,
                        paddingBottom: 12,
                        paddingTop: 12,
                        borderRadius: 32,
                        fontFamily: "Montserrat"
                      }}
                    >
                      {medicationButton}
                    </ColorButton>
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="d-flex">
          <CheckCircleIcon
            className={checkAction3}
            style={{ marginRight: "2px", marginTop: "28px", fontFamily: "Montserrat" }}
          />
          <Accordion
            className="w-100"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={{
              py: 0.4,
              my: 0.8,
              backgroundColor: "#F7F7FC",
              borderRadius: 5,
              boxShadow: 0,
              fontFamily: "Montserrat",
              right: "0px"            
            }}
            style={{width: "30%"}}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600, fontWeight: "600!important", fontFamily: "Montserrat!important", color: "#14142B", marginTop: "15px"   }}>
                Survey Answers
              </Typography>
              {/* <div
                        className="text-center"
                        style={{
                          color: "#610BEF",
                          marginTop: 45,
                          marginBottom: 6,
                          fontWeight: 700,
                          fontFamily: "Montserrat"
                        }}
                      >
                        {markAsSurveyAns}
              </div> */}
            </AccordionSummary>
            <AccordionDetails>
              {survey &&
                survey.map((item, index) => (
                  <div key={index} className="d-flex">
                    <div className="p-1">
                      <p style={{ fontWeight: 600, fontFamily: "Montserrat" }}>Q{index + 1}.</p>
                    </div>
                    <div style={{ width: "100%"}}>
                      <div
                        className="pl-2"
                        style={{ fontWeight: 400, letterSpacing: 1.0, fontFamily: "Montserrat" }}
                      >
                        {item.question_description} 
                          <span style={{ color: "#610BEF", fontWeight: 600, fontFamily: "Montserrat" }}>
                          </span>
                          {item.multiple_options && item.multiple_options.length !== 0 &&item.multiple_type_id === 1 ? (
                            <div style={{margin: "10px", padding: "10px", width: "90%"}}>
                              <CustomizedDropdown
                                key={index}
                                id={"survey_demo-simple-select" + index}
                                value={item.answer}
                                placeholder="Select Answer"
                                name={"surveyAns_"+index}
                                onSelect={(value) => (SurveyanwhandleSelectChange(value, index))}
                                options={item.multiple_options.map((item, idx) => {
                                  return {
                                    label: item.name,
                                    value: `${item.id}`,
                                  }
                                })}
                                style={{marginLeft:"-1em" , width:"100%", fontSize: "1em"}}
                              />
                              {/* <Select
                                  id="survey_demo-simple-select"
                                  value={state["surveyAns_"+index] === null || state["surveyAns_"+index] === undefined ? item.answer : state["surveyAns_"+index]}
                                  name ={"surveyAns_"+index}
                                  className="login-form w-100"
                                  onChange={(e)=>SurveyanwhandleSelectChange(e,"surveyAns_"+index)}
                                  style={colourStyles}
                                >
                                {item.multiple_options.map((_mitem,_index)=>(                                
                                    <MenuItem key={_index} value={_index}>{_mitem.name}</MenuItem>
                                ))}
                              </Select> */}
                            </div>
                          ):""}
                          {item.multiple_type_id === 3 && item.multiple_options && item.multiple_options.length !== 0 ? (
                            <div style={{margin: "10px", padding: "10px", width: "90%"}}>
                              <CustomizedMultiselect
                                key={index}
                                id={"survey_demo-simple-select" + index}
                                value={item.answer}
                                placeholder="Select Answer"
                                name={"surveyAns_"+index}
                                onSelect={(value) => (SurveyanwhandleSelectChange(value, index))}
                                defaultOptions={item.multiple_options.map((item, idx) => {
                                  return {
                                    label: item.name,
                                    value: `${item.id}`,
                                  }
                                })}
                                style={{marginLeft:"-1em" , width:"100%", fontSize: "1em"}}
                              />
                            </div>
                          ):""
                          }
                        <div style={{width: "120%"}}>
                        {item.multiple_type_id &&
                            item.multiple_type_id === 2 ?
                            (
                                <input
                                  type="text"
                                  name="recommendation"
                                  placeholder="Answer"
                                  className="login-form"
                                  onChange={(e)=>surveryAnsText(e,"surveyAns_"+index)}
                                  value={state["surveyAns_"+index] === null || state["surveyAns_"+index] === undefined ? item.answer : state["surveyAns_"+index]}
                                  style={{width:"73%"}}
                                />
                            ):""
                            }
                          </div>
                      </div>
                    </div>
                  </div>
                ))}
                {survey && survey.length > 0 && !surveyAnsMakeAsView? (
                  <ColorButton
                      className="w-25"
                      variant="contained"
                      onClick={completeSurvery}
                      style={{
                        float: "right",
                        marginTop: 12,
                        marginBottom: 12,
                        paddingBottom: 12,
                        paddingTop: 12,
                        borderRadius: 32,
                        fontFamily: "Montserrat"
                      }}
                    >
                      Save
                  </ColorButton>
                ) : (<div/>)}
                <div
                        className="text-center"
                        style={{
                          color: "#610BEF",
                          marginTop: 45,
                          marginBottom: 6,
                          fontWeight: 700,
                          fontFamily: "Montserrat"
                        }}
                      >
                        {markAsSurveyAns}
                </div>
                {surveyAnsMakeAsView ? (
                  <ColorButton
                  className="w-100"
                  variant="contained"
                  onClick={markasreviewSurvey}
                  style={{
                    paddingBottom: 12,
                    paddingTop: 12,
                    borderRadius: 32,
                    fontFamily: "Montserrat"
                  }}
                  >
                    Mark As Reviewed
                  </ColorButton>
                ):(<div/>)}
                
            </AccordionDetails>
            
          </Accordion>
        </div>
        <div className="d-flex">
          <CheckCircleIcon
            className={checkAction6}
            style={{ marginRight: "2px", marginTop: "28px", fontFamily: "Montserrat"}}
          />
          <Accordion
            className="w-100"
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            sx={{
              py: 0.4,
              my: 0.8,
              backgroundColor: "#F7F7FC",
              borderRadius: 5,
              boxShadow: 0,
              fontFamily: "Montserrat"
            }}
            style={{width: "30%"}}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600, fontWeight: "600!important", fontFamily: "Montserrat!important", color: "#14142B", marginTop: "15px"   }}>
                Past Visit Notes
              </Typography>
              {/* <div
                        className="text-center"
                        style={{
                          color: "#610BEF",
                          marginTop: 45,
                          marginBottom: 6,
                          fontWeight: 700,
                          fontFamily: "Montserrat"
                        }}
                        hidden
                      >
                        {markPastVisitTxt}
                </div> */}

            </AccordionSummary>
            <AccordionDetails>
              {pastVisitSummary.length === 0 && (
                  <div className="mt-3 d-flex justify-content-center w-100" style={{
                    backgroundColor: "#ECF0F1",
                    borderRadius: 15,
                    padding: 12,
                    boxShadow: "none",
                    fontFamily: "Montserrat",
                    alignItems: "center",
                    minHeight: "90px",
                    fontWeight: "bold",
                    fontSize: "700"
                  }}>
                        No Past Visits Recorded
                  </div>
                )
              }
              {pastVisitSummary && 
                pastVisitSummary.map((item, index) => (
                  <Accordion
                    key={index}
                    className="mt-3"
                    expanded={subExpanded === "panel1" + (index + 1)}
                    onChange={subhandleChange("panel1" + (index + 1))}
                    style={{
                      backgroundColor: "#ECF0F1",
                      borderRadius: 15,
                      padding: 12,
                      boxShadow: "none",
                      fontFamily: "Montserrat"
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id={`panel${index + 1}d-header`}
                    >
                      <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                          <div>
                            <img
                              className="img-circle"
                              src={item["doctor_info"]["avatar"]}
                              style={{ width: 84, height: 84 }}
                            />
                          </div>
                          <div className="align-self-center pl-3 pt-3">
                            <p style={{ fontWeight: 700, fontFamily: "Montserrat" }}>
                              Dr. {item["doctor_info"]["last_name"]}
                            </p>
                            <p style={{ fontWeight: 400, fontFamily: "Montserrat" }}>
                              {item["doctor_info"]["speciality"]}
                            </p>
                          </div>
                        </div>
                        <div
                          className="align-self-center"
                          style={{ textAlign: "right", fontFamily: "Montserrat" }}
                        >
                          <p style={{ fontWeight: 400, fontFamily: "Montserrat" }}>
                            {item["reviewed_date"]}
                          </p>
                          <p style={{ fontWeight: 400, fontFamily: "Montserrat" }}>
                            {Moment(item["created_at"]).format("hh:mm a")}
                          </p>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="pt-4">
                          <p style={{ fontWeight: 700, fontSize: 19, fontFamily: "Montserrat" }}>
                            After Visit Summary
                          </p>
                        </div>
                        <div>
                          <p className="fw-400" style={{ fontFamily: "Montserrat"}}>Dear {patient.patient_name}.</p>
                          <p className="fw-400 pt-1" style={{ fontFamily: "Montserrat"}}>On{" "}
                            {Moment(item["reviewed_date"]).format("MMM DD, YYYY")}{"  "}
                            we comprehensively reviewed your medication for safety and discussed how well it is meeting your needs.
                          </p>
                        </div>
                        <div className="py-2">
                          <div>
                            <p className="fw-600" style={{ fontFamily: "Montserrat"}}>
                              During the course of our review, it was our goal
                              to:
                            </p>
                          </div>
                          <div className="w-85 mx-auto" style={{ fontFamily: "Montserrat"}}>
                            <ul>
                              {item["goals"] && item["goals"].length > 0 && item["goals"].map((subitem,_mindex) => (
                                <li key={_mindex}
                                  style={{ fontWeight: 400, lineHeight: 1.4 }}
                                >
                                  {subitem["value"]!==null&&subitem["value"]!==undefined?subitem["value"]:subitem}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div>
                          <div>
                            <p className="fw-600" style={{ fontFamily: "Montserrat"}}>
                              Your Current Medications Are
                            </p>
                          </div>
                          <div>
                            {item["medications"].map((item, i) => (
                              <ListItem
                                key={i}
                                disableGutters
                                sx={{
                                  borderRadius: 4,
                                  backgroundColor: "#610BEF",
                                  my: 3,
                                  py: 0.5,
                                  fontFamily: "Montserrat"
                                }}
                                className="w-85 mx-auto"
                              >
                                <IconButton onClick={handleAction1}>
                                  <div className="active" style={{marginLeft:"5px", width:"15px", height:"15px"}}>

                                  </div>
                                  <CheckBoxIcon
                                    className=""
                                    sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                                  />
                                </IconButton>
                                <ListItemText
                                  className="active"
                                  secondary={
                                    <React.Fragment>
                                      <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["medicationname"]+" - "+item["strength"]}</small>
                                      <br/>
                                      <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["directions"]}</small>
                                    </React.Fragment>
                                  }
                                  sx={{ px: 2, color: "#FFFFFF", fontSize: "15px", fontFamily: "Montserrat" }}
                                />
                              </ListItem>
                            ))}
                          </div>
                        </div>
                        <div className="py-2" style={{ fontFamily: "Montserrat" }}>
                          <div>
                            <p className="fw-600">Care Gaps As Noted on Survey</p>
                          </div>
                          <div className="w-85 mx-auto">
                              <ul>
                                {item["care_gaps_as_noted_on_survey"].map((subitem,_mindex) => (
                                  <li key={_mindex}
                                    style={{ fontWeight: 400, lineHeight: 1.4 }}
                                  >
                                    {subitem}
                                  </li>
                                ))}
                              </ul>
                          </div>
                        </div>
                        <div className="py-2">
                          {(!Array.isArray(item["medication_interaction"]) || item["medication_interaction"].length === 0) && (
                            <p className="fw-400" style={{ fontFamily: "Montserrat"}}>
                              We found that you had no interaction between all medications.
                              <br/>
                            </p>
                          )}
                          {Array.isArray(item["medication_interaction"]) && (item["medication_interaction"].map((interaction, __index) => (
                            <p className="fw-400" key={__index} style={{ fontFamily: "Montserrat"}}>
                              {interaction}
                              <br/>
                            </p>
                          )))}
                          <div className="w-85 mx-auto" style={{ fontFamily: "Montserrat"}}>
                            <ul>
                              <li>
                                {item["recommendation_for_resolution"]}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div style={{ padding: 8, fontFamily: "Montserrat" }}>
                          <p
                            style={{ fontWeight: 400, lineHeight: 1.6 }}
                          >
                            {item["notes"]}
                          </p>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              <div className="pt-3" style={{ fontFamily: "Montserrat"}}>
                { markPastVisitTxt !== undefined && markPastVisitTxt !== null && markPastVisitTxt !== "" &&
                  (<div
                    className="text-center"
                    style={{
                      color: "#610BEF",
                      marginTop: 45,
                      marginBottom: 6,
                      fontWeight: 700,
                      fontFamily: "Montserrat"
                    }}
                  >
                    {markPastVisitTxt}
                  </div>)
                }
                <ColorButton
                  className="w-100"
                  variant="contained"
                  style={{
                    paddingBottom: 12,
                    paddingTop: 12,
                    borderRadius: 32,
                  }}
                  onClick={markasPastVisitNote}
                  
                >
                  Mark As Reviewed
                </ColorButton>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="d-flex flex-row">
          <CheckCircleIcon
            className={checkAction4}
            style={{ marginRight: "2px", marginTop: "28px" }}
          />
          <Accordion
            className="w-100"
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            sx={{
              py: 0.4,
              my: 0.8,
              backgroundColor: "#F7F7FC",
              borderRadius: 5,
              boxShadow: 0,
              fontFamily: "Montserrat"
            }}
            style={{width: "30%"}}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600, fontWeight: "600!important", fontFamily: "Montserrat!important", color: "#000000", marginTop: "15px"   }}>
                Gaps In Care
              </Typography>
              {/* { markGapsInCare !== undefined && markGapsInCare !== null && markGapsInCare !== "" &&
                (<div
                  className="text-center"
                  style={{
                    color: "#610BEF",
                    marginTop: 45,
                    marginBottom: 6,
                    fontWeight: 700,
                    fontFamily: "Montserrat"
                  }}
                >
                  {markGapsInCare}
                </div>)
              } */}
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div className="p-2" style={{ fontFamily: "Montserrat"}}>
                  <p className="fw-600">
                    The Following Medication Interactions were Noted
                  </p>
                </div>
                <div>
                  {chatMedication.map((item, i) => (
                    <ListItem
                      key={i}
                      disableGutters
                      sx={{
                        borderRadius: 4,
                        backgroundColor: "#610BEF",
                        my: 3,
                        py: 0.5,
                        fontFamily: "Montserrat"
                      }}
                      className="w-85 mx-auto"
                    >
                      <IconButton onClick={(e) => clickMedication(item, i)}>
                        <div className="active" id={"medication"+i} style={{marginLeft:"5px", width:"15px", height:"15px"}}>

                        </div>
                        <CheckBoxIcon
                          className=""
                          sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                        />
                      </IconButton>
                      <ListItemText
                        className="active"
                        secondary={
                          <React.Fragment>
                            <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["medicationname"]+" - "+item["strength"]}</small>
                            <br/>
                            <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["direction"]}</small>
                          </React.Fragment>
                        }
                        sx={{ px: 2, color: "#FFFFFF", fontWeight: "15px" }}
                      />
                    </ListItem>
                  ))}
                </div>
              </div>
              <div className="p-2">
                <div>
                  <p className="fw-600" style={{ fontFamily: "Montserrat" }}>Input Recommendation for Resolution</p>
                </div>
                <div className="w-85 mx-auto p-3" style={{ borderRadius: 12, fontFamily: "Montserrat" }}>
                  {/* Yevgeniy edited */}
                  <div onClick={gapsincareedit}>
                    <textarea
                      rows="3"
                      cols="40"
                      id="recommendation_edit"
                      ref={recommendationRef}
                      disabled = {gapsincarestatus === "enable"? false : true}
                      style={{ padding: 14, fontFamily: "Montserrat" }}
                      name="recommendation"
                      placeholder="Recommendation for resolution"
                      className="edit-form"
                      onChange={textedithandler}
                      onClick={gapsincareedit}
                      value={recommendation_for_resolution}
                    />
                  </div>
                  {/* Yevgeniy end */}
                </div>
              </div>
              <div className="p-2" style={{ fontFamily: "Montserrat" }}>
                <div>
                  <p className="fw-600">Care Gaps As Noted on Survey</p>
                </div>
                <div className="w-85 mx-auto p-3">
                    <ul>
                    { Array.isArray(care_gaps_as_noted_on_survey) &&
                      care_gaps_as_noted_on_survey.map((item, index) => (
                        <li key={index} style={{ fontWeight: 400, lineHeight: 1.4 }}>
                          {item}
                        </li>
                      ))
                    }
                    </ul>
                    {/* <ul>
                      <li style={{ fontWeight: 400, lineHeight: 1.4 }}>
                        There may be drug related problems for medications not found in claims history.
                      </li>
                      <li style={{ fontWeight: 400, lineHeight: 1.4 }}>
                        This patient is at risk for care coordination issues and has barries to obtaining care.
                      </li>
                      <li style={{ fontWeight: 400, lineHeight: 1.4 }}>
                        This access may be affecting compliance.
                      </li>
                    </ul> */}
                  
                  {markStatus === 1 && (
                    <textarea
                      rows="5"
                      cols="40"
                      id="textedit"
                      style={{ padding: 14, fontFamily: "Montserrat" }}
                      placeholder="Care Gaps As Noted on Survey"
                      className="edit-form"
                      onChange={textedithandler}
                    >
                      {careGaps}
                    </textarea>
                  )}
                </div>
              </div>
              <div className="p-2" style={{ fontFamily: "Montserrat" }}>
                <div>
                  <p className="fw-600">Notes Addressing Patient’s Needs</p>
                </div>
                <div className="w-85 mx-auto p-3" style={{ borderRadius: 12 }}>
                  <div onClick={gapsincareedit}>
                    <textarea
                      rows="7"
                      cols="40"
                      id="notes_edit"
                      ref={notesRef}                    
                      disabled = {gapsincarestatus === "enable"? false : true}
                      style={{ padding: 14, fontFamily: "Montserrat" }}
                      name="notes"
                      placeholder="Notes Addressing Patient’s Needs"
                      className="edit-form"
                      onChange={textedithandler}
                      onClick={gapsincareedit}
                      value={notes}
                    />
                  </div>
                </div>
              </div>
              { markGapsInCare !== undefined && markGapsInCare !== null && markGapsInCare !== "" &&
                (<div
                  className="text-center"
                  style={{
                    color: "#610BEF",
                    marginTop: 45,
                    marginBottom: 6,
                    fontWeight: 700,
                    fontFamily: "Montserrat"
                  }}
                >
                  {markGapsInCare}
                </div>)
              }
              <div style={{ padding: 8,  fontFamily: "Montserrat" }}>
                {/* Yevgeniy edited */}
                { gapsincarestatus === "enable" ? (
                  <ColorButton
                    className="w-100"
                    variant="contained"
                    style={{
                      paddingBottom: 12,
                      paddingTop: 12,
                      borderRadius: 32,
                    }}
                    onClick={save_gaps_info}
                  >
                  Save                                                 {/**{button_text[markStatus]} */}
                  </ColorButton>) :
                  (
                    <ColorButton
                      className="w-100"
                      variant="contained"
                      style={{
                        paddingBottom: 12,
                        paddingTop: 12,
                        borderRadius: 32,
                      }}
                      onClick={markasGapsInCare}
                    >
                    Mark As Reviewed                                                 {/**{button_text[markStatus]} */}
                    </ColorButton>
                  )
                }
                {/* Yevgeniy end  description button status changed -- {markStatus === 1 ? save_gaps_info : changeStatus}*/}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="d-flex">
          <CheckCircleIcon
            className={checkAction5}
            style={{ marginRight: "2px", marginTop: "28px",  fontFamily: "Montserrat" }}
          />
          <Accordion
            className="w-100"
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            sx={{
              py: 0.4,
              my: 0.8,
              backgroundColor: "#F7F7FC",
              borderRadius: 5,
              boxShadow: 0,
              fontFamily: "Montserrat"
            }}
            style={{width: "30%"}}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              {/** Yevgeniy edited */}
              <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600, fontWeight: "600!important", fontFamily: "Montserrat!important", color: "#14142B",marginTop: "15px"   }}>
                After Visit Summary
              </Typography>
              {/* { markAfterVisit !== undefined && markAfterVisit !== null && markAfterVisit !== "" &&
                (<div
                  className="text-center"
                  style={{
                    color: "#610BEF",
                    marginTop: 45,
                    marginBottom: 6,
                    fontWeight: 700,
                    fontFamily: "Montserrat"
                  }}
                >
                  {markAfterVisit}
                </div>)
              } */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              {afterVisitSummary !== "" && afterVisitSummary !==undefined && afterVisitSummary !==null && (
                <div style={{ fontFamily: "Montserrat" }}>
                  <div>
                    <p className="fw-400" style={{ fontFamily: "Montserrat" }}>Dear {patient.patient_name}.</p>
                    <p className="fw-400 pt-1" style={{ fontFamily: "Montserrat" }}>
                      On{" "}
                            {Moment(afterVisitSummary["reviewed_date"]).format("MMM DD, YYYY")}{"  "} 
                        {afterVisitSummary["short_text"]}                
                    </p>
                  </div>
                  <div className="py-2" style={{ fontFamily: "Montserrat" }}>
                    <div>
                      <p className="fw-600">
                        During the course of our review, it was our goal to:
                      </p>
                    </div>
                    <div className="w-85 mx-auto">
                        <ul>
                          {afterVisitSummary["goals"].map((subitem,_mindex) => (
                            <li key={_mindex}
                              style={{ fontWeight: 400, lineHeight: 1.4 }}
                            >
                              {subitem}
                            </li>
                          ))}
                        </ul>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="fw-600">Your Current Medications Are</p>
                    </div>
                    <div>
                      {Array.isArray(afterVisitSummary["medications"]) && (afterVisitSummary["medications"].map((item, i) => (
                        <ListItem
                          key={i}
                          disableGutters
                          sx={{
                            borderRadius: 4,
                            backgroundColor: "#610BEF",
                            my: 3,
                            py: 0.5,
                          }}
                          className="w-85 mx-auto"
                        >
                          <IconButton onClick={handleAction1}>
                            <div className="active" style={{marginLeft:"5px", width:"15px", height:"15px"}}>

                            </div>
                            <CheckBoxIcon
                              className=""
                              sx={{ color: "#914DFF", outline: "none", position: "absolute", marginLeft: "5px" }}
                            />
                          </IconButton>
                          <ListItemText
                            className="active"
                            secondary={
                              <React.Fragment>
                                <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["medicationname"]+" - "+item["strength"]}</small>
                                <br/>
                                <small style={{color:"#FFFFFF", fontSize:"15px", padding: "0px", fontFamily: "Montserrat"}}>{item["directions"]}</small>
                              </React.Fragment>
                            }
                            sx={{ px: 2, color: "#FFFFFF" }}
                          />
                        </ListItem>
                      )))}
                    </div>
                  </div>
                  <div className="py-2" style={{ fontFamily: "Montserrat" }}>
                    <div>
                      <p className="fw-600">Care Gaps As Noted on Survey</p>
                    </div>
                    <div className="w-85 mx-auto">
                        <ul>
                          {afterVisitSummary["care_gaps_as_noted_on_survey"].map((subitem,_mindex) => (
                            <li key={_mindex}
                              style={{ fontWeight: 400, lineHeight: 1.4 }}
                            >
                              {subitem}
                            </li>
                          ))}
                        </ul>
                    </div>
                  </div>
                  <div className="py-2">
                    <div>
                      {(!Array.isArray(afterVisitSummary["medication_interaction"]) || afterVisitSummary["medication_interaction"].length === 0) && (
                        <p className="fw-400" style={{ fontFamily: "Montserrat"}}>
                          We found that you had no interaction between all medications.
                          <br/>
                        </p>
                      )}
                      {Array.isArray(afterVisitSummary["medication_interaction"]) && afterVisitSummary["medication_interaction"].map((interaction, __index) => (
                        <p className="fw-400" key={__index} style={{ fontFamily: "Montserrat"}}>
                          {interaction}
                          <br/>
                        </p>
                      ))}
                    </div>
                    <div className="w-85 mx-auto">
                      <ul>
                        <li style={{ fontWeight: 400, lineHeight: 1.4 }}>
                          {afterVisitSummary["recommendation_for_resolution"]}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div style={{ padding: 8 }}>
                    <p
                      style={{ fontWeight: 400, lineHeight: 1.6 }}
                    >
                      {afterVisitSummary["notes"]}
                    </p>
                  </div>
                </div>
              )}
              { markAfterVisit !== undefined && markAfterVisit !== null && markAfterVisit !== "" &&
                (<div
                  className="text-center"
                  style={{
                    color: "#610BEF",
                    marginTop: 45,
                    marginBottom: 6,
                    fontWeight: 700,
                    fontFamily: "Montserrat"
                  }}
                >
                  {markAfterVisit}
                </div>)
              }
                { afterVisitReview === "disable" ? (
                  <ColorButton
                    className="w-100"
                    variant="contained"
                    style={{
                      paddingBottom: 12,
                      paddingTop: 12,
                      borderRadius: 32,
                    }}
                    onClick={
                      save_after_visit_summary
                    }
                  >
                    Mark As Done
                  </ColorButton>) :
                  (<ColorButton
                    className="w-100"
                    variant="contained"
                    style={{
                      paddingBottom: 12,
                      paddingTop: 12,
                      borderRadius: 32,
                    }}
                    onClick={
                      markasAfterVisit
                    }
                  >
                    Mark As Review
                  </ColorButton>)
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
