import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {keyboard_arrow_up, keyboard_arrow_down} from "@material/react-material-icon"
const Root = styled('div')`
  font-family: Montserrat;
  font-size: 1.2rem;
  position: relative;
  display: start;
  width: 100%;
  vertical-align: baseline;
  color: #000;
`;

const Toggle = styled('div')`
  min-width: 150px;
  min-height: calc(1.5em + 10px);
  margin-right: -3px;
  padding: 22px 22px 22px 12px;
  background-color: #EFF0F6;
  display: center;
  align-items: center;
  justify-content: start;
  color: #000000;
  cursor: default;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  border-radius: 18px;
  & .placeholder {
    color: #898989;
  }
  & .dropdown-icon {
    position: absolute;
    right: 20px;
    top: 20%;
  }
`;

const Listbox = styled('ul')`
  background: #EFF0F6;
  list-style: none;
  padding: 5px 0 0 0;
  margin: -10px 5px 0 0;
  border-radius: 0 0 10px 10px;
  position: absolute;
  height: auto;
  transition: opacity 0.1s ease;
  width: 100%;
  z-index: 1000;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s 0.1s ease, visibility 0.4s 0.5s step-end;
  }

  & > div > li {
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    &:hover {
      background: #ccc;
    }

    &[aria-selected='true'] {
      background: #ccc;
    }
  }
`;

function CustomSelect({ options, placeholder, style, onSelect, value }) {
  const listboxRef = React.useRef(null);
  const [listboxVisible, setListboxVisible] = React.useState(false);
  const [value1, setValue1] = React.useState("");

  const clickEffect = (e) => {
    e.preventDefault();
    if (listboxVisible) {
      setListboxVisible(false);
    } else {
      setListboxVisible(true);
    }
  };

  const selectItem = (id) => {
    //value = options[id];
    if(onSelect !== undefined)
    {
      onSelect(options[id].value);
      value = options[id].value;
      setValue1(value);
      
    }
    setListboxVisible(false);
  }

  const onToggleButton = () => {
    setListboxVisible(!listboxVisible);
  }

  let label = '';

  if(value1 === "")
  {
    options.map((option) =>
      option.value === value ? (label = option.label) : ''
    );
  }
  else {
    options.map((option) =>
      option.value === value1 ? (label = option.label) : ''
    );
  }
  return (
    <Root onBlur={() => setListboxVisible(false)} style={style}>
      <Toggle onClick={e => onToggleButton()}>
        {label ? (
          label
        ) : (
          <span className="placeholder">{placeholder ?? ' '}</span>
        )}{' '}
        <span
          className="dropdown-icon material-icons"
          style={{ fontSize: '30px', color: '#4E4B66', marginTop: "8px" }}
          onClick={(e) => clickEffect(e)}
        >
          {listboxVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </span>
      </Toggle>
      <Listbox
        className={listboxVisible ? '' : 'hidden'}
      >
        {options.map((option, id) => (
          <div key={option.value} onClick={(e) => selectItem(id)}>
            <li key={option.value}>            
              {option.label}         
            </li>
          </div>
        ))}
      </Listbox>
    </Root>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.node,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
};

export default CustomSelect;
